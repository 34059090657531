<template>
  <div>
    

    <div class="row justify-center">
      <div style="width: 300px;" class="col-lg-6 col-md-6 cols-12 d-flex justify-start">
          <p class="error">{{ error }}</p>
          <!-- <p  class="decode-result">Last result: <b>{{ result }}</b></p> -->
      </div>
    </div>
    <div class="row justify-center">
      <div  class="col-lg-6 col-md-6 cols-12 d-flex justify-center">
        <qrcode-stream style="width: 300px;" @decode="onDecode" @init="onInit" />
      </div>
    </div>
  </div>
</template>

<script>


import { QrcodeStream } from 'vue-qrcode-reader'
// import { QrcodeStream } from '../../../../src'
import Swal from "sweetalert2";
export default {

  components: { QrcodeStream },

  data () {
    return {
      finalUrl : "",
      result: '',
      error: '',
      brandId: "",
      verificationCode: "",

    }
  },

  async created(){
    this.brandId = this.$route.params.brandId;
    this.verificationCode = this.$route.params.verificationCode;
    this.finalUrl = this.$store.getters["common/qrUrl"]  + "/mobile-scan-qr/" + brand.uid + "/" + brand.verificationCode;


    // console.log("ON DECODE >>> this.brandId >>>  ",this.brandId, " this.verificationCode >>>  ",this.verificationCode)
  },

  methods: {
    async onDecode (result) {
      this.result = result;

      let param = {
        brandId : this.brandId,
        verificationCode : this.verificationCode,
        couponCode : this.result
      };
      param.code = result;
      param.campaignId = this.$route.params.campaignId;

      // console.log("ON DECODE >>> param >>>  ",param)


      try{
            let res = null; //await this.$store.dispatch("coupon/used",param);
            // this.$router.push("/mobile-scan-success/".concat(result));

                Swal.fire({
                    icon: 'warning',
                    showCancelButton: true,
                    text: `ทำการใช้คูปองใช่หรือไม่`,
                    confirmButtonText: "ใช่ ต้องการใช้คูปอง",
                })
                .then( async (result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.value) {
                            res = await this.$store.dispatch("coupon/used",param);
                            
                            // window.location.href = this.finalUrl;

                            if(res === null || res === false){
                                Swal.fire({
                                    title: "มีบางอย่างไม่ถูกต้อง",
                                    text: "คูปองที่สแกน ยังไม่ถูกใช้งาน",
                                    icon: "warning",
                                    confirmButtonClass: "btn btn-secondary",
                                    confirmButtonText: "OK",
                              
                                  }).then( res1 => {
                                    if (res1.value) {
                                      window.location.href = this.finalUrl;
                                    }
                                  });

                            }else{
                                  Swal.fire({
                                      icon: 'success',
                                      // showCancelButton: true,
                                      title: `ทำการใช้คูปองเรียบร้อยแล้ว`,
                                      confirmButtonText: "รับทราบ",
                                  }).then( res2 => {
                                    if (res2.value) {
                                      window.location.href = this.finalUrl;
                                    }
                                  });

                            }
                        
                      } 
                    });

      }
      catch(err){
            Swal.fire({
                    title: "มีบางอย่างไม่ถูกต้อง",
                    text: "คูปองที่แสกนยังไม่ถูกใช้งาน",
                    icon: "warning",
                    confirmButtonClass: "btn btn-secondary"
              });

              return;
      
      }

          

      


      // Swal.fire({
      //           title: "Scan ใช้งานเรียบร้อยแล้ว",
      //           text:this.result,
      //           icon: "success",
      //           confirmButtonClass: "btn btn-secondary"
      // });
      
    },
    // watch: {
    //     result(value){
    //         // if(value !== null && value !== undefined)
    //         // {
    //         //     Swal.fire({
    //         //         title: "Scan ใช้งานเรียบร้อยแล้ว",
    //         //         text: "อัพโหลดไฟล์ Excel เรียบร้อยแล้ว",
    //         //         icon: "success",
    //         //         confirmButtonClass: "btn btn-secondary"
    //         //     });
    //         // }
    //     }
    // },

    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    }
  }
}
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
</style>

<style >
.wrapper{
  padding-top : 0 !important;
  margin-top : 0 !important;
}

.content{
  padding-top : 0 !important;
  margin-top : 0 !important;
}

.topbar {
  display: none !important;
}

#kt_header{
    display: none !important;
}
</style>
